import React, { useState, FC, MouseEvent, ChangeEvent, useEffect } from "react";
import { validateInput } from "../../utils/validators/Validators";
import {
  TextField,
  IconButton,
  TextFieldProps,
  InputAdornment,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import { FormValidators } from "../Form";

interface OwnProps {
  leftIcon?: string;
  inputValue?: string;
  field?: string;
  typeValue?: string;
  textChange?: (
    value: string,
    field: string,
    error?: { error: boolean; message: string },
  ) => void;
  validators?: FormValidators[];
  hasError?: boolean;
  fieldError?: string;
  startAdornment?: string;
  endAdornment?: string;
}

const InputField: FC<OwnProps & TextFieldProps> = ({
  hasError,
  validators,
  textChange,
  field,
  inputValue,
  placeholder,
  fieldError,
  inputProps,
  InputProps,
  variant,
  disabled,
  label,
  size = "medium",
  typeValue,
  style,
  autoFocus,
  sx,
  className,
  startAdornment,
  endAdornment,
  ...rest
}) => {
  const [error, setError] = useState<{
    error: boolean;
    message: string;
  } | null>(null);
  const [pass, setPass] = useState(false);

  useEffect(() => {
    setError({
      error: hasError as boolean,
      message: fieldError || "",
    });
  }, [fieldError, hasError]);

  const handleChange = (value: string) => {
    const inputError = validateInput(validators as FormValidators[], value);
    setError(inputError);
    if (textChange) {
      textChange(value, field as string, inputError);
    }
  };

  const handleTogglePassword = () => {
    setPass(!pass);
  };

  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <TextField
      autoFocus={autoFocus}
      sx={{ marginBottom: 3, ...sx }}
      size={size}
      autoComplete="off"
      inputProps={inputProps}
      InputProps={
        typeValue === "password"
          ? {
              ...InputProps,
              endAdornment: (
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleTogglePassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {pass ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              ),
            }
          : {
              ...InputProps,
              startAdornment: !!startAdornment ? (
                <InputAdornment position="start">
                  {startAdornment}
                </InputAdornment>
              ) : undefined,
              endAdornment: !!endAdornment ? (
                <InputAdornment position="end">{endAdornment}</InputAdornment>
              ) : undefined,
            }
      }
      style={style}
      disabled={disabled}
      type={pass ? "text" : typeValue}
      helperText={error && error.error ? error.message : ""}
      error={!!(error && error.error)}
      color="primary"
      fullWidth
      className={`${!!hasError} ${className}`}
      value={inputValue}
      onChange={(e: ChangeEvent<HTMLInputElement>) =>
        textChange ? handleChange(e.target.value) : e
      }
      placeholder={placeholder}
      label={label}
      variant={variant}
      {...rest}
    />
  );
};
export default InputField;
