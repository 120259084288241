import { variables } from "../style/_variables";

export const styles = {
  button: {
    borderRadius: "15px",
    padding: "15px 35px",
    boxShadow: "none",
  },
  submit_button: {
    background: `${variables.preplixPrimary}`,
    "&:hover": {
      background: `${variables.preplixPrimary}`,
    },
  },
  reset_button: {
    background: `${variables.sectionPrimary}`,
    marginRight: "10px",
    color: "#000000",
    "&:hover": {
      background: `${variables.sectionPrimary}`,
    },
  },
  pagination: {
    "& .Mui-selected": {
      backgroundColor: `${variables.preplixPrimary} !important`,
      color: "white",
    },
  },
  filter_item: {
    paddingX: 2,
    borderRight: `1px solid ${variables.preplixPrimary}`,
    "&:last-child": {
      borderRight: "none",
    },
    "@media (max-width: 1021px)": {
      borderRight: "none",
    },
  },
  modalContent: {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: "12px",
    minWidth: "950px",
    maxHeight: "calc(100vh - 50px)",
    overflow: "hidden",
    overflowY: "scroll",
  },
  btnPrimary: {
    background: `${variables.preplixPrimary}`,
    marginRight: "2rem",
    marginTop: "10px",
    padding: "10px 12px",
    minWidth: "173px",
    borderRadius: "8px",

    "&:hover": {
      background: "#D9D9D9",
      color: `${variables.preplixPrimary}`,
    },
  },
  btnSecondary: {
    color: "#232326",
    background: "#D9D9D9",
    marginLeft: "2rem",
    marginRight: "2rem",
    marginTop: "10px",
    padding: "10px 12px",
    minWidth: "173px",
    borderRadius: "8px",

    "&:hover": {
      background: `${variables.preplixPrimary}`,
      color: "white",
    },
  },
};
