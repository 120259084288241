import React, { FC, RefObject, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Form, { FormDataModel } from "../../../components/Form";
import { LoginForm } from "./LoginForm";
import PrimaryButton from "../../../components/PrimaryButton";
import { removeErrorFieldsFromValues } from "../../../utils/validators";
import { useDispatch } from "react-redux";
import { setAuthentication } from "../../../utils/redux";
import { setNotificationMessage } from "../../../utils/redux";
import { identifiers } from "../../../utils/constants/identifiers";
import { URLS } from "../../../utils/constants/urls";
import { Grid, Typography } from "@mui/material";
import { routes } from "../../../utils/constants/routes";
import CircularProgress from "@mui/material/CircularProgress";
import { styles } from "./styles";
import axiosInstance from "../../../utils/axios";

const Login: FC = () => {
  const Navigate = useNavigate();
  const dispatch = useDispatch();

  let loginForm: RefObject<Form | null | undefined> = useRef();

  const [hasError, setHasError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const handleLogin = async () => {
    setLoading(true);
    const { getFormData } = loginForm.current as {
      getFormData: () => { formData: FormDataModel; isFormValid: boolean };
    };
    const { formData, isFormValid } = getFormData();
    const body: FormDataModel = removeErrorFieldsFromValues(formData);
    try {
      setHasError(false);
      if (isFormValid) {
        const { status, data } = await axiosInstance.post(URLS.login, {
          grantType: "password",
          ...body,
        });
        if (status === 200) {
          setLoading(false);
          let isAdmin = false;
          data.authorities.forEach((role: { authority: string }) => {
            if (role.authority !== "TEACHER" && role.authority !== "STUDENT") {
              isAdmin = true;
            }
          });
          if (isAdmin) {
            dispatch(setAuthentication(data));
            Navigate(routes.dashboard);
          } else {
            dispatch(
              setNotificationMessage({
                display: true,
                severity: "error",
                message: "You are not Authorized to view this App",
              }),
            );
          }
        }
      } else {
        setHasError(true);
        setLoading(false);
      }
    } catch (e: any) {
      setLoading(false);
      if (e.response) {
        dispatch(
          setNotificationMessage({
            display: true,
            severity: "error",
            message: e.response.data.message,
          }),
        );
      } else {
        dispatch(
          setNotificationMessage({
            display: true,
            severity: "error",
            message: "You are not Authorized to view this App",
          }),
        );
      }
    }
  };

  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <Typography variant="h3" sx={styles.welcome}>
          Welcome!
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5" sx={{ mb: 1 }} fontWeight={500}>
          Sign in to
        </Typography>
        <Typography variant="h6" sx={{ mb: 3 }} fontWeight={300}>
          Preplix
        </Typography>
      </Grid>
      <Form
        hasError={hasError}
        fieldError={identifiers.field_error as string}
        ref={loginForm as RefObject<Form>}
        model={LoginForm()}
        values={{}}
      />
      {/*<Grid item xs={12} sx={{my: 2}} display={"flex"} justifyContent={"flex-end"}>*/}
      {/*    <Stack alignSelf={"flex-end"} width={"fit-content"}>*/}
      {/*        <Link to={routes.forgot_password}>*/}
      {/*            <Typography*/}
      {/*                variant="body1"*/}
      {/*                sx={{*/}
      {/*                    fontSize: 12,*/}
      {/*                    fontWeight: 500,*/}
      {/*                    color: "#5AB9F9",*/}
      {/*                }}*/}
      {/*                align={"right"}*/}
      {/*            >*/}
      {/*                Forgot Password ?*/}
      {/*            </Typography>*/}
      {/*        </Link>*/}
      {/*    </Stack>*/}
      {/*</Grid>*/}
      <PrimaryButton
        disabled={loading}
        type="submit"
        color={"secondary"}
        sx={{
          mt: 1,
          borderRadius: "24px 0px 24px 0px",
          p: 1,
        }}
        onClick={handleLogin}
      >
        {loading && <CircularProgress sx={{ mr: 1 }} size={20} />}
        Log In
      </PrimaryButton>
    </Grid>
  );
};

export default Login;
