import React, { FC, useEffect, useState } from "react";
import "./styles.css";
import { validateInput } from "../../utils/validators/Validators";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Box,
  SelectProps,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { FormValidators } from "../Form";

interface OwnProps {
  inputValue?: string;
  field?: string;
  typeValue?: string;
  textChange?: (
    value: string,
    field: string,
    error?: { error: boolean; message: string }
  ) => void;
  validators?: FormValidators[];
  hasError?: boolean;
  fieldError?: boolean | string;
  options: { label: string; value: string; icon?: string }[];
}

const DropDownInput: FC<OwnProps & SelectProps> = ({
  hasError,
  validators,
  textChange,
  field,
  inputValue,
  options,
  fieldError,
  inputProps,
  disabled,
  className,
  id,
  labelId,
  label,
  style,
  sx,
}) => {
  const [error, setError] = useState<{
    error: boolean;
    message: string;
  } | null>(null);

  useEffect(() => {
    if (!fieldError) {
      setError(null);
    }
  }, [fieldError]);

  const handleChange = (value: string) => {
    const inputError = validateInput(validators as FormValidators[], value);
    setError(inputError);
    if (textChange) {
      textChange(value, field as string, inputError);
    }
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: "#5AB9F9",
      },
    },
  });

  return (
    <FormControl
      size="small"
      error={!!((error && error.error) || (!error && hasError && fieldError))}
      className={`dropdown ${className}`}
      fullWidth
    >
      <InputLabel id={label as string}>{label}</InputLabel>
      <ThemeProvider theme={theme}>
        <Select
          sx={sx}
          style={style}
          inputProps={inputProps}
          disabled={disabled}
          labelId={labelId}
          id={id}
          value={inputValue}
          label={label}
          onChange={(event) => {
            handleChange(event.target.value);
          }}
        >
          {options.map((item, i) => (
            <MenuItem key={i} value={item.value}>
              {!!item.icon && (
                <Box
                  sx={{ mr: 2 }}
                  className={`flag-icon flag-icon-${item.icon.toLowerCase()}`}
                />
              )}
              <Box>{item.label}</Box>
            </MenuItem>
          ))}
        </Select>
      </ThemeProvider>

      <FormHelperText>
        {error && error.error
          ? error.message
          : !error && hasError && fieldError
          ? "This field is required"
          : ""}
      </FormHelperText>
    </FormControl>
  );
};
export default DropDownInput;
