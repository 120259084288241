import { FormDataModel } from '../../components/Form';

export const getFormFields: (formData: FormDataModel) => {
  [p: string]: string | number | boolean;
} = (formData: FormDataModel) => {
  const fields: { [key: string]: string | number | boolean } = {};
  for (const key in formData) {
    if (!key.includes('Error')) {
      fields[key] = formData[key];
    }
  }
  return fields;
};

export const removeErrorFieldsFromValues = (formData: FormDataModel) => {
  const fields: FormDataModel = {};
  for (const key in formData) {
    if (!key.includes('Error')) {
      fields[key] = formData[key];
    }
  }
  return fields;
};
