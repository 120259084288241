import React, { FC } from "react";
import CircularProgress from "@mui/material/CircularProgress";

const PageLoader: FC = () => (
  <div className="loader_wrapper">
    <div className="spinner-border text-primary" role="status" />
    <CircularProgress color="primary" />
  </div>
);

export default PageLoader;
