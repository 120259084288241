import { FC } from "react";
import UserListing from "../../Common/UserListing/UserListing";

interface Column {
  id: keyof UserManagementData;
  label: string;
  minWidth: number;
  align: "right" | "left";
}

const Columns: Column[] = [
  { id: "sno", label: "S.No", minWidth: 50, align: "left" },
  { id: "name", label: "Employee Name", minWidth: 150, align: "left" },
  { id: "id", label: "Emp ID", minWidth: 100, align: "left" },
  { id: "designation", label: "Designation", minWidth: 150, align: "left" },
  { id: "email", label: "Email ID", minWidth: 150, align: "left" },
];

export interface UserManagementData {
  sno?: number;
  name: string;
  id: string;
  designation: string;
  email: string;
  selectedPrivileges: { id: number; name: string; selected: boolean }[];
}

const UserManagement: FC = () => {
  return <UserListing columns={Columns} title={"staff"} />;
};

export default UserManagement;
