export const styles = {
  headerCont: {
    width: "100% !important",
    padding: "10px 0",
  },
  sidebarCont: {
    background: "#DEEDF7",
    marginTop: "84px",
    "& .MuiDrawer-paper": {
      marginTop: "84px",
      borderTopRightRadius: "75px",
      background: "linear-gradient(180deg, #E30928 0%, #820517 100%)",
      color: "white",
    },
  },
  searchBar: {
    background: "#FFFF",
    borderRadius: "12px",
    width: "100%",
    padding: "10px",
    "& .MuiInput-root::before, & .MuiInput-root::after, & .MuiInput-root:hover:not(.Mui-disabled):before":
      {
        borderBottom: "none", // Remove bottom border
      },
  },
  navIcon: {
    flexDirection: "row",
    width: "55px",
    background: "white",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    height: "55px",
    borderRadius: "12px",
  },
  sideIcon: {
    width: "25px",
  },
};
