import { AnyAction, configureStore, EnhancedStore } from "@reduxjs/toolkit";
import { ThunkMiddlewareFor } from "@reduxjs/toolkit/dist/getDefaultMiddleware";
import authenticationSlice from "../reducer/authentication-slice";
import notificationSlice from "../reducer/notification-slice";
import profileSlice from "../reducer/profile-slice";

const store: EnhancedStore<any, AnyAction, [ThunkMiddlewareFor<any>]> =
  configureStore({
    reducer: {
      authenticationSlice,
      notificationSlice,
      profileSlice,
    },
  });

export default store;
