import { Country, State, City, IState, ICountry } from "country-state-city";
import { FormModel } from "../../../components/Form";
import { Validators } from "../../../utils/validators";
import { DropDown } from "../../../utils/constants/identifiers";
import { listing_status } from "../../../utils/constants/constants";

export const FeesForm: () => FormModel[] = () => {
  return [
    {
      label: "For 1 Class",
      value: "",
      size: "medium",
      type: "text",
      typeValue: "text",
      variant: "outlined",
      placeholder: "Price for 1 Class",
      field: "fees",
      validators: [
        { check: Validators.required, message: "This field is mandatory" },
      ],
      responsive: { xs: 12 },
      required: true,
      sx: {
        backgroundColor: "#FFFFFF",
        marginBottom: 0,
        borderRadius: "15px",
        "& .MuiInputBase-root": {
          border: "1px solid #FFA7B7",
          borderRadius: "15px",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
      },
      inputProps: {
        sx: {
          border: "none",
          padding: "15px",
          paddingLeft: "15px",
        },
      },
      hiddenLabel: true,
    },
    {
      label: "For 5 Classes",
      value: "",
      size: "medium",
      type: "text",
      typeValue: "text",
      variant: "outlined",
      placeholder: "Price for 5 Classes",
      field: "fees5",
      validators: [
        { check: Validators.required, message: "This field is mandatory" },
      ],
      responsive: { xs: 12 },
      required: true,
      sx: {
        backgroundColor: "#FFFFFF",
        marginBottom: 0,
        borderRadius: "15px",
        "& .MuiInputBase-root": {
          border: "1px solid #FFA7B7",
          borderRadius: "15px",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
      },
      inputProps: {
        sx: {
          border: "none",
          padding: "15px",
          paddingLeft: "15px",
        },
      },
      hiddenLabel: true,
    },
    {
      label: "For 10 Classes",
      value: "",
      size: "medium",
      type: "text",
      typeValue: "text",
      variant: "outlined",
      placeholder: "Price for 10 Classes",
      field: "fees10",
      validators: [
        { check: Validators.required, message: "This field is mandatory" },
      ],
      responsive: { xs: 12 },
      required: true,
      sx: {
        backgroundColor: "#FFFFFF",
        marginBottom: 0,
        borderRadius: "15px",
        "& .MuiInputBase-root": {
          border: "1px solid #FFA7B7",
          borderRadius: "15px",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
      },
      inputProps: {
        sx: {
          border: "none",
          padding: "15px",
          paddingLeft: "15px",
        },
      },
      hiddenLabel: true,
    },
  ];
};

export const CourseForm: (courses: DropDown[]) => FormModel[] = (courses) => {
  return [
    {
      label: "Choose Course",
      value: "",
      size: "small",
      type: "drop-down",
      typeValue: "text",
      variant: "outlined",
      placeholder: "",
      field: "courseName",
      options: courses,
      validators: [],
      responsive: { xs: 12 },
      required: true,
      sx: {
        backgroundColor: "#FFFFFF",
        marginBottom: 0,
        borderRadius: "15px",
        border: "1px solid #FFA7B7",
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
      },
      inputProps: {
        sx: {
          border: "none",
          padding: "15px",
          paddingLeft: "15px",
        },
      },
      hiddenLabel: true,
    },
    {
      label: "Mode of Learning",
      value: "",
      size: "small",
      type: "drop-down",
      typeValue: "text",
      variant: "outlined",
      placeholder: "",
      field: "mode",
      options: [
        // { label: "Hybrid", value: "Hybrid" },
        // { label: "One to One", value: "One to One" },
        { label: "Online", value: "Online" },
        { label: "Offline", value: "Offline" },
      ],
      validators: [],
      responsive: { xs: 12 },
      required: true,
      sx: {
        backgroundColor: "#FFFFFF",
        marginBottom: 0,
        borderRadius: "15px",
        border: "1px solid #FFA7B7",
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
      },
      inputProps: {
        sx: {
          border: "none",
          padding: "15px",
          paddingLeft: "15px",
        },
      },
      hiddenLabel: true,
    },
    {
      label: "Language",
      value: "",
      size: "small",
      type: "drop-down",
      typeValue: "text",
      variant: "outlined",
      placeholder: "",
      field: "language",
      options: [
        { label: "English", value: "English" },
        { label: "Hindi", value: "Hindi" },
      ],
      validators: [],
      responsive: { xs: 12 },
      required: true,
      sx: {
        backgroundColor: "#FFFFFF",
        marginBottom: 0,
        borderRadius: "15px",
        border: "1px solid #FFA7B7",
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
      },
      inputProps: {
        sx: {
          border: "none",
          padding: "15px",
          paddingLeft: "15px",
        },
      },
      hiddenLabel: true,
    },
  ];
};

export const LocationForm: (location: {
  country: string;
  state: string;
  city: string;
}) => FormModel[] = ({ country, state, city }) => {
  const selected_country: ICountry = Country.getAllCountries().find(
    (countries) => countries.name === country
  ) as ICountry;
  let selected_state: IState | undefined;
  if (!!country) {
    selected_state = State.getStatesOfCountry("IN").find(
      (states) => states.name === state
    ) as IState;
  }
  return [
    {
      label: "Country",
      value: "",
      size: "small",
      type: "drop-down",
      typeValue: "text",
      variant: "outlined",
      placeholder: "",
      field: "country",
      options: Country.getAllCountries().map((country) => ({
        label: country.name,
        value: country.name,
      })),
      validators: [],
      responsive: { xs: 12 },
      required: true,
      sx: {
        backgroundColor: "#FFFFFF",
        marginBottom: 0,
        borderRadius: "15px",
        border: "1px solid #FFA7B7",
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
      },
      inputProps: {
        sx: {
          border: "none",
          padding: "15px",
          paddingLeft: "15px",
        },
      },
      hiddenLabel: true,
    },
    {
      label: "State",
      value: "",
      size: "small",
      type: "drop-down",
      typeValue: "text",
      variant: "outlined",
      placeholder: "",
      field: "state",
      options: !!country
        ? State.getStatesOfCountry(selected_country.isoCode).map((state) => ({
            label: state.name,
            value: state.name,
          }))
        : [],
      validators: [],
      responsive: { xs: 12 },
      required: true,
      sx: {
        backgroundColor: "#FFFFFF",
        marginBottom: 0,
        borderRadius: "15px",
        border: "1px solid #FFA7B7",
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
      },
      inputProps: {
        sx: {
          border: "none",
          padding: "15px",
          paddingLeft: "15px",
        },
      },
      hiddenLabel: true,
      disabled: !country,
    },
    {
      label: "City",
      value: "",
      size: "small",
      type: "drop-down",
      typeValue: "text",
      variant: "outlined",
      placeholder: "",
      field: "city",
      options:
        !!country && !!state && !!selected_state
          ? City.getCitiesOfState(
              selected_country.isoCode,
              selected_state.isoCode
            ).map((city) => ({
              label: city.name,
              value: city.name,
            }))
          : [],
      validators: [],
      responsive: { xs: 12 },
      required: true,
      disabled: !state,
      sx: {
        backgroundColor: "#FFFFFF",
        marginBottom: 0,
        borderRadius: "15px",
        border: "1px solid #FFA7B7",
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
      },
      inputProps: {
        sx: {
          border: "none",
          padding: "15px",
          paddingLeft: "15px",
        },
      },
      hiddenLabel: true,
    },
    {
      label: "Area",
      value: "",
      size: "small",
      type: "text",
      typeValue: "text",
      variant: "outlined",
      placeholder: "Address",
      field: "locality",
      validators: [],
      responsive: { xs: 12 },
      required: true,
      sx: {
        backgroundColor: "#FFFFFF",
        marginBottom: 0,
        borderRadius: "15px",
        "& .MuiInputBase-root": {
          border: "1px solid #FFA7B7",
          borderRadius: "15px",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
      },
      inputProps: {
        sx: {
          border: "none",
          padding: "15px",
          paddingLeft: "15px",
        },
      },
      hiddenLabel: true,
      disabled: !city,
    },
  ];
};

export const StatusForm: () => FormModel[] = () => {
  return [
    {
      label: "Status",
      value: "",
      size: "small",
      type: "drop-down",
      typeValue: "text",
      variant: "outlined",
      placeholder: "",
      field: "status",
      options: listing_status,
      validators: [],
      responsive: { xs: 12 },
      required: true,
      sx: {
        backgroundColor: "#FFFFFF",
        marginBottom: 0,
        borderRadius: "15px",
        border: "1px solid #FFA7B7",
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
      },
      inputProps: {
        sx: {
          border: "none",
          padding: "15px",
          paddingLeft: "15px",
        },
      },
      hiddenLabel: true,
    },
  ];
};
