import React, { FC } from "react";
import { Card, Grid } from "@mui/material";

import { styles } from "./styles";

const AuthenticationLayout: FC<{ Component: FC }> = ({ Component }) => {
  return (
    <Grid container spacing={0} sx={styles.container}>
      <Grid item xs={12} lg={5} sx={styles.card_container}>
        <Card sx={styles.card}>
          <Component />
        </Card>
      </Grid>
    </Grid>
  );
};

export default AuthenticationLayout;
