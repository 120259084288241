import { routes } from "./routes";
import { DropDown } from "./identifiers";

export const drawerWidth: number = 220;

export const menu = [
  {
    icon: "money",
    title: "All Loans",
    link: routes.dashboard,
  },
];

export const listing_status: DropDown[] = [
  {
    label: "Pending",
    value: "pending",
  },
  {
    label: "Approved",
    value: "approved",
  },
  {
    label: "Rejected",
    value: "rejected",
  },
];
