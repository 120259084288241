import React, { FC, useCallback, useEffect, useState } from "react";
import { Container, Grid, Typography } from "@mui/material";
import DashboardCard from "../../../components/DashboardCard/index";
import Button from "../../../components/Button/Button";
import { variables } from "../../../utils/style/_variables";
import axiosInstance from "../../../utils/axios/index";
import { URLS } from "../../../utils/constants/urls";
import { logout, setNotificationMessage } from "../../../utils/redux";
import PageLoader from "../../../components/PageLoader/index";
import { useDispatch } from "react-redux";
import { routes } from "../../../utils/constants/routes";

const buttonData = [
  {
    title: "Update Profile",
    url: "/profile",
  },
  {
    title: "View Listings",
    url: routes.listing,
  },
  {
    title: "Applications",
    url: routes.applications,
  },
  {
    title: "Make Announcement",
    url: "/Make_Announcement",
  },
  {
    title: "Create Test",
    url: "/Create_Test",
  },
];

interface DashboardCounters {
  noOfCourses: number | null;
  averageRating: number | null;
  noOfInquiries: number | null;
  noOfStudentsTrained: number | null;
  totalRevenue: number | null;
  noOfTrainingHoursConducted: number | null;
  noOfSuccessfulApplications: number | null;
  noOfClassesConducted: number | null;
}

const DashboardPage: FC = () => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [counters, setCounters] = useState<DashboardCounters>();

  const getCounters = useCallback(async () => {
    try {
      setLoading(true);
      const { data }: { data: DashboardCounters } = await axiosInstance.get(
        URLS.dashboard_counters("admin"),
      );
      setLoading(false);
      setCounters(data);
    } catch (e: any) {
      setLoading(false);
      if (e.response) {
        if (e.response.status === 401) {
          dispatch(logout());
        } else {
          dispatch(
            setNotificationMessage({
              display: true,
              severity: "error",
              message: e.response.data.message,
            }),
          );
        }
      }
      console.log(e);
    }
  }, [dispatch]);

  useEffect(() => {
    (async () => {
      await getCounters();
    })();
  }, [getCounters]);

  return (
    <Container
      maxWidth={false}
      disableGutters={true}
      sx={{
        backgroundColor: "#DEEDF7",
        padding: "20px",
        height: "auto",
        minHeight: "100vh",
      }}
    >
      {loading && <PageLoader />}
      {!!counters && (
        <Grid container spacing={2}>
          <Grid item lg={3}>
            <DashboardCard
              name={"Listings"}
              count={counters.noOfCourses || 0}
            />
          </Grid>
          <Grid item lg={3}>
            <DashboardCard
              name={"Average Rating"}
              count={counters.averageRating || 0}
            />
          </Grid>
          <Grid item lg={3}>
            <DashboardCard
              name={"Queries Received"}
              count={counters.noOfInquiries || 0}
            />
          </Grid>
          <Grid item lg={3}>
            <DashboardCard
              name={"Students Trained"}
              count={counters.noOfStudentsTrained || 0}
            />
          </Grid>
          <Grid item lg={3}>
            <DashboardCard
              name={"Revenue Generated"}
              count={counters.totalRevenue || 0}
            />
          </Grid>
          <Grid item lg={3}>
            <DashboardCard
              name={"Hours Trained"}
              count={counters.noOfTrainingHoursConducted || 0}
            />
          </Grid>
          <Grid item lg={3}>
            <DashboardCard
              name={"Applications Processed"}
              count={counters.noOfSuccessfulApplications || 0}
            />
          </Grid>
          <Grid item lg={3}>
            <DashboardCard
              name={"Classes Conducted"}
              count={counters.noOfClassesConducted || 0}
            />
          </Grid>
        </Grid>
      )}
      <Grid container marginY={5} spacing={2}>
        {buttonData.map((button, index) => (
          <Grid
            item
            key={index}
            lg={6}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Button
              variant={"outlined"}
              sx={[
                {
                  border: "1px solid #D10724",
                  borderRadius: "15px",
                  backgroundColor: "#ffffff",
                  cursor: "pointer",
                  width: "100%",
                  height: "65px",
                  color: "#000000",
                  "&:hover": {
                    border: "1px solid #D10724",
                    backgroundColor: variables.preplixPrimary,
                    color: "#ffffff",
                  },
                },
              ]}
              href={button.url}
            >
              <Typography fontWeight={700} fontSize={"16px"}>
                {button.title}
              </Typography>
            </Button>
          </Grid>
        ))}
      </Grid>
      <Grid container marginY={5} spacing={2}>
        <Grid item lg={6}>
          <Grid
            container
            justifyContent={"space-between"}
            alignItems="center"
            borderRadius={"10px"}
            padding={3}
            boxShadow={"0px 0px 10px 0px rgba(0, 0, 0, 0.1)"}
            sx={{
              border: "1px solid #F1F1F1",
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
              backgroundColor: "white",
            }}
          >
            <Grid item lg={8}>
              <Typography color={"#232326"} fontWeight={600} fontSize={"20px"}>
                Student Ratings
              </Typography>
            </Grid>
            {/*<Grid*/}
            {/*  item*/}
            {/*  lg={4}*/}
            {/*  container*/}
            {/*  alignItems="center"*/}
            {/*  justifyContent="flex-end"*/}
            {/*>*/}
            {/*  <Button variant={"text"} sx={[{ color: "#095089" }]}>*/}
            {/*    <Typography fontWeight={600} fontSize={"14px"}>*/}
            {/*      See All*/}
            {/*    </Typography>*/}
            {/*  </Button>*/}
            {/*</Grid>*/}
          </Grid>
          <Grid
            container
            justifyContent={"space-between"}
            alignItems="center"
            borderRadius={"10px"}
            paddingX={3}
            sx={{
              border: "1px solid #F1F1F1",
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              backgroundColor: "white",
            }}
          >
            <Grid item xs={12} textAlign={"center"} paddingY={"10px"}>
              <Typography color={"#232326"} fontSize={"15px"}>
                No Recent Student Ratings
              </Typography>
            </Grid>
          </Grid>
          {/*{studentRating.map((feedback, index) => (*/}
          {/*  <Grid*/}
          {/*    key={index}*/}
          {/*    container*/}
          {/*    alignItems="center"*/}
          {/*    my={3}*/}
          {/*    px={1}*/}
          {/*    justifyContent="space-between"*/}
          {/*  >*/}
          {/*    <Grid item>*/}
          {/*      <Avatar*/}
          {/*        alt="Profile Photo"*/}
          {/*        src="/path/to/profile_photo.jpg"*/}
          {/*        sx={{ width: 64, height: 64, borderRadius: 4 }}*/}
          {/*      />*/}
          {/*    </Grid>*/}
          {/*    <Grid item>*/}
          {/*      <Typography color="#232326" fontWeight={400} fontSize="16px">*/}
          {/*        {feedback.name}*/}
          {/*      </Typography>*/}
          {/*      <Typography color="#676767" fontWeight={400} fontSize="12px">*/}
          {/*        {feedback.feedback}*/}
          {/*      </Typography>*/}
          {/*    </Grid>*/}
          {/*    <Grid item textAlign="center">*/}
          {/*      <Rating*/}
          {/*        name="rating"*/}
          {/*        value={feedback.rating}*/}
          {/*        precision={0.5}*/}
          {/*        readOnly*/}
          {/*      />*/}
          {/*      <Typography color="#676767" fontWeight={400} fontSize="12px">*/}
          {/*        {feedback.rating.toFixed(1)}/5*/}
          {/*      </Typography>*/}
          {/*    </Grid>*/}
          {/*    <Grid item textAlign="center">*/}
          {/*      <Typography color="#676767" fontWeight={400}>*/}
          {/*        {feedback.course}*/}
          {/*      </Typography>*/}
          {/*      <Typography color="#676767" fontWeight={400}>*/}
          {/*        {feedback.time}*/}
          {/*      </Typography>*/}
          {/*    </Grid>*/}
          {/*  </Grid>*/}
          {/*))}*/}
        </Grid>
        <Grid item lg={6}>
          <Grid
            container
            justifyContent={"space-between"}
            alignItems="center"
            borderRadius={"10px"}
            padding={3}
            boxShadow={"0px 0px 10px 0px rgba(0, 0, 0, 0.1)"}
            sx={{
              border: "1px solid #F1F1F1",
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
              backgroundColor: "white",
            }}
          >
            <Grid item lg={8}>
              <Typography color={"#232326"} fontWeight={600} fontSize={"20px"}>
                Student Queries
              </Typography>
            </Grid>
            {/*<Grid*/}
            {/*  item*/}
            {/*  lg={4}*/}
            {/*  container*/}
            {/*  alignItems="center"*/}
            {/*  justifyContent="flex-end"*/}
            {/*>*/}
            {/*  <Button variant={"text"} sx={[{ color: "#095089" }]}>*/}
            {/*    <Typography fontWeight={600} fontSize={"14px"}>*/}
            {/*      See All*/}
            {/*    </Typography>*/}
            {/*  </Button>*/}
            {/*</Grid>*/}
          </Grid>
          <Grid
            container
            justifyContent={"space-between"}
            alignItems="center"
            borderRadius={"10px"}
            paddingX={3}
            sx={{
              border: "1px solid #F1F1F1",
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              backgroundColor: "white",
            }}
          >
            <Grid item xs={12} textAlign={"center"} paddingY={"10px"}>
              <Typography color={"#232326"} fontSize={"15px"}>
                No Recent Student Queries
              </Typography>
            </Grid>
          </Grid>
          {/*{studentRating.map((feedback, index) => (*/}
          {/*  <Grid*/}
          {/*    key={index}*/}
          {/*    container*/}
          {/*    alignItems="center"*/}
          {/*    my={3}*/}
          {/*    justifyContent="space-between"*/}
          {/*  >*/}
          {/*    <Grid item>*/}
          {/*      <Avatar*/}
          {/*        alt="Profile Photo"*/}
          {/*        src="/path/to/profile_photo.jpg"*/}
          {/*        sx={{ width: 64, height: 64, borderRadius: 4 }}*/}
          {/*      />*/}
          {/*    </Grid>*/}
          {/*    <Grid item>*/}
          {/*      <Typography color="#232326" fontWeight={400} fontSize="16px">*/}
          {/*        {feedback.name}*/}
          {/*      </Typography>*/}
          {/*      <Typography color="#676767" fontWeight={400} fontSize="12px">*/}
          {/*        {feedback.feedback}*/}
          {/*      </Typography>*/}
          {/*    </Grid>*/}
          {/*    <Grid*/}
          {/*      item*/}
          {/*      lg={2.2}*/}
          {/*      py={1}*/}
          {/*      px={2}*/}
          {/*      textAlign={"center"}*/}
          {/*      borderRadius={"10px"}*/}
          {/*      sx={{ backgroundColor: "#09508926", cursor: "pointer" }}*/}
          {/*    >*/}
          {/*      <Typography*/}
          {/*        color={"#095089"}*/}
          {/*        fontWeight={600}*/}
          {/*        fontSize={"14px"}*/}
          {/*      >*/}
          {/*        {"View"}*/}
          {/*      </Typography>*/}
          {/*    </Grid>*/}
          {/*    <Grid*/}
          {/*      item*/}
          {/*      lg={2.2}*/}
          {/*      py={1}*/}
          {/*      px={2}*/}
          {/*      textAlign={"center"}*/}
          {/*      borderRadius={"10px"}*/}
          {/*      sx={{ backgroundColor: "#EA252526", cursor: "pointer" }}*/}
          {/*    >*/}
          {/*      <Typography*/}
          {/*        color={"#D10724"}*/}
          {/*        fontWeight={600}*/}
          {/*        fontSize={"14px"}*/}
          {/*      >*/}
          {/*        {"Decline"}*/}
          {/*      </Typography>*/}
          {/*    </Grid>*/}
          {/*  </Grid>*/}
          {/*))}*/}
        </Grid>
      </Grid>
      <Grid container marginY={5} spacing={2}>
        <Grid item lg={6}>
          <Grid
            container
            justifyContent={"space-between"}
            alignItems="center"
            borderRadius={"10px"}
            padding={3}
            boxShadow={"0px 0px 10px 0px rgba(0, 0, 0, 0.1)"}
            sx={{
              border: "1px solid #F1F1F1",
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
              backgroundColor: "white",
            }}
          >
            <Grid item lg={8}>
              <Typography color={"#232326"} fontWeight={600} fontSize={"20px"}>
                Upcoming Classes
              </Typography>
            </Grid>
            {/*<Grid*/}
            {/*  item*/}
            {/*  lg={4}*/}
            {/*  container*/}
            {/*  alignItems="center"*/}
            {/*  justifyContent="flex-end"*/}
            {/*>*/}
            {/*  <Button variant={"text"} sx={[{ color: "#095089" }]}>*/}
            {/*    <Typography fontWeight={600} fontSize={"14px"}>*/}
            {/*      See All*/}
            {/*    </Typography>*/}
            {/*  </Button>*/}
            {/*</Grid>*/}
          </Grid>
          <Grid
            container
            justifyContent={"space-between"}
            alignItems="center"
            borderRadius={"10px"}
            paddingX={3}
            sx={{
              border: "1px solid #F1F1F1",
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              backgroundColor: "white",
            }}
          >
            <Grid item xs={12} textAlign={"center"} paddingY={"10px"}>
              <Typography color={"#232326"} fontSize={"15px"}>
                No Upcoming Classes
              </Typography>
            </Grid>
          </Grid>
          {/*{studentRating.map((feedback, index) => (*/}
          {/*    <Grid key={index} container alignItems="center" my={3}>*/}
          {/*      <Grid item>*/}
          {/*        <Grid*/}
          {/*            container*/}
          {/*            borderRadius={3}*/}
          {/*            sx={{ backgroundColor: "#095089" }}*/}
          {/*            p={2}*/}
          {/*        >*/}
          {/*          <Grid item lg={12}>*/}
          {/*            <Typography*/}
          {/*                color={"white"}*/}
          {/*                fontWeight={600}*/}
          {/*                fontSize={"14px"}*/}
          {/*                textAlign={"center"}*/}
          {/*            >*/}
          {/*              {"07"}*/}
          {/*            </Typography>*/}
          {/*          </Grid>*/}
          {/*          <Grid item lg={12}>*/}
          {/*            <Typography*/}
          {/*                color={"#C5C5C5"}*/}
          {/*                fontWeight={600}*/}
          {/*                fontSize={"14px"}*/}
          {/*                textAlign={"center"}*/}
          {/*            >*/}
          {/*              {"May"}*/}
          {/*            </Typography>*/}
          {/*          </Grid>*/}
          {/*        </Grid>*/}
          {/*      </Grid>*/}
          {/*      <Grid item ml={2}>*/}
          {/*        <Typography color="#232326" fontWeight={400} fontSize="16px">*/}
          {/*          {feedback.name}*/}
          {/*        </Typography>*/}
          {/*        <Typography color="#676767" fontWeight={400} fontSize="12px">*/}
          {/*          {feedback.feedback}*/}
          {/*        </Typography>*/}
          {/*      </Grid>*/}

          {/*      <Grid item lg={2.5} ml={26}>*/}
          {/*        <Grid container alignItems="center">*/}
          {/*          <Grid*/}
          {/*              item*/}
          {/*              lg={12}*/}
          {/*              py={1}*/}
          {/*              px={2}*/}
          {/*              textAlign={"center"}*/}
          {/*              borderRadius={"10px"}*/}
          {/*              sx={{ backgroundColor: "#EA2525", cursor: "pointer" }}*/}
          {/*          >*/}
          {/*            <Typography*/}
          {/*                color={"white"}*/}
          {/*                fontWeight={600}*/}
          {/*                fontSize={"14px"}*/}
          {/*            >*/}
          {/*              {"Start Classes"}*/}
          {/*            </Typography>*/}
          {/*          </Grid>*/}
          {/*          <Grid item ml={2} mt={1}>*/}
          {/*            <Typography*/}
          {/*                color={"#676767"}*/}
          {/*                fontWeight={400}*/}
          {/*                fontSize={"12px"}*/}
          {/*            >*/}
          {/*              {"10:00 AM - 11:00 AM"}*/}
          {/*            </Typography>*/}
          {/*          </Grid>*/}
          {/*        </Grid>*/}
          {/*      </Grid>*/}
          {/*    </Grid>*/}
          {/*))}*/}
        </Grid>
      </Grid>
    </Container>
  );
};
export default DashboardPage;
