import { FC } from "react";

import UserListing from "../../Common/UserListing/UserListing";

interface Column {
  id: keyof TeacherData;
  label: string;
  minWidth: number;
  align: "right" | "left";
}

const TeacherColumns: Column[] = [
  { id: "id", label: "Teacher ID", minWidth: 100, align: "left" },
  { id: "name", label: "Name", minWidth: 150, align: "left" },
  { id: "email", label: "Email", minWidth: 150, align: "left" },
  { id: "phone", label: "Mobile Number", minWidth: 100, align: "left" },
  { id: "city", label: "City", minWidth: 100, align: "left" },
];

export interface TeacherData {
  id?: string;
  name: string;
  email: string;
  phone: string;
  city: string;
  country: string;
}

const TeacherListing: FC = () => {
  return <UserListing columns={TeacherColumns} title={"teacher"} />;
};

export default TeacherListing;
